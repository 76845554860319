@import "@/utils/mixins";
@import "@/utils/variables";

.afterIcon,
.beforeIcon {
	display: flex;
	flex-direction: row;
	gap: 10px;

	& input {
		flex: 1;
	}
}

.searchWrapper {
	position: relative;

	span {
		position: absolute;
		left: 10px;
		top: 10px;
	}

	input {
		padding-left: 40px;
	}
}

.passwordWrapper {
	position: relative;

	input {
		padding-right: 50px;
	}

	& span {
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		cursor: pointer;
		font-size: 1.4rem;
		line-height: 1.6rem;
		color: $deep-green;
	}
}

.textArea {
	background: $white;
	border: 1px solid $light-grey;
	width: 100%;
	@include font-size(18);
	padding: 10px;
	text-align: center;
	font-size: 1.8rem;
	box-shadow: none !important;
	font-family: $base-font-family;
	transition: all 0.4s;
	height: 148px;
	line-height: 2rem;
	color: $deep-green;
	resize: none;

	[type="password"]::-ms-clear,
	[type="password"]::-ms-reveal {
		display: none;
	}

	&::placeholder {
		font-weight: $normal-base;
		font-size: 1.6rem;
		line-height: 18px;
		color: $light-grey;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"],
	&[type="tel"] {
		width: 100%;
		padding: 10px;
		-moz-appearance: textfield;
	}

	&[type="date"] {
		height: 53px;
	}

	&:focus {
		outline: none !important;
		box-shadow: 0px 0px 5px 0px rgba($deep-green, 0.3) !important;
	}

	&.inputError {
		border-color: $red;
		color: $red;
	}

	&.inputDown {
		padding: 15px 10px 5px;
	}

	&.inputSmall {
		width: 65px;
		@include tabletLarge() {
			width: 80px;
		}
	}
	&.inputSelect {
		cursor: pointer;
	}
}

.input {
	background: $white;
	border: 1px solid $light-grey;
	width: 100%;
	@include font-size(18);
	padding: 10px;
	text-align: center;
	font-size: 1.8rem;
	box-shadow: none !important;
	font-family: $base-font-family;
	transition: all 0.4s;
	height: 48px;
	line-height: 48px;
	color: $deep-green;

	[type="password"]::-ms-clear,
	[type="password"]::-ms-reveal {
		display: none;
	}

	&:disabled {
		opacity: 0.7;
		cursor: default;
	}

	&::placeholder {
		font-weight: $normal-base;
		font-size: 1.6rem;
		line-height: 18px;
		color: $light-grey;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type="number"],
	&[type="tel"] {
		width: 100%;
		padding: 10px;
		-moz-appearance: textfield;
	}

	&[type="date"] {
		height: 53px;
	}

	&:focus {
		outline: none !important;
		box-shadow: 0px 0px 5px 0px rgba($deep-green, 0.3) !important;
	}

	&.inputError {
		border-color: $red;
		color: $red;
	}

	&.inputDown {
		padding: 15px 10px 5px;
	}

	&.inputSmall {
		width: 65px;
		@include tabletLarge() {
			width: 80px;
		}
	}
	&.inputSelect:not(:disabled) {
		cursor: pointer;
	}
}

.extraText {
	display: inline-block;
	margin-left: 5px;
	font-weight: $normal-base;
	font-size: 1.8rem;
	line-height: 48px;
}

.floatingError {
	position: absolute;
	background-color: $red;
	margin-top: 10px;
	z-index: 2;
	font-size: 1.6rem;
	font-weight: normal;
	padding: 2px 6px;

	p {
		font-size: 1.4rem;
		line-height: 2.2rem;
		padding: 0;
		margin: 0;
		font-weight: normal;
		color: $white;
	}
}
