@import "@/utils/variables";

.emailForm {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;

    div {
        flex-grow: 1;
    }

    button {
        min-width: fit-content;
    }

    .error {
        p {
            color: $pink;
        }
    }
}

.confirmation {
    padding: 10px 10px;
    margin-bottom: 30px;
    background-color: $faded-deep-green;
    border: 1px solid $light-green;
    display: flex;
    gap: 7px;

    p {
        color: $deep-green !important;
        margin: 0;
        font-weight: $bold-base;
        align-self: flex-end;
    }

    svg {
        color: $deep-green;
    }
}