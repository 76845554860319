@import "@/utils/mixins";
@import "@/utils/variables";

.error {
	color: $red;
	margin-top: $gutter / 2;
	font-weight: bold;
	margin-bottom: 0;
	font-weight: $normal-base;
	font-size: 1.4rem;
	line-height: 1.6rem;
}
