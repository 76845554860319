@import "@/utils/mixins";
@import "@/utils/variables";

.heroWrapper {
	text-align: center;

	p {
		margin-left: auto;
		margin-right: auto;

		@include tablet() {
			max-width: 500px;
		}

		@include desktop() {
			max-width: 600px;
		}
	}

	.header {
		line-height: 3rem;

		@include desktop() {
			line-height: 4.4rem;
		}
	}
}

.reviewTimelineHeader {
	margin-bottom: 10px;
	margin-top: 10px;
}

.noMargin {
	margin: 0;
}

.divider {
	grid-column: 1 / span 6;
	margin: 20px 0;

	@include tablet() {
		margin: 60px 0;
		grid-column: 3 / span 8;
	}
}

.accordion {
	@include tablet() {
		grid-column: 3 / span 8;
	}
}

.step {
	font-size: 1.8rem;
	line-height: 2.2rem;
	font-weight: $bold-base;
	color: $white;
	margin-bottom: 15px;
	margin-top: 10px;

	@include tabletLarge() {
		font-size: 2rem;
	}

	& i {
		font-style: normal;
		color: $orange;
	}
}
