@import "@/utils/mixins";
@import "@/utils/variables";

.wrapper {
	display: flex;
	gap: 10px;
	align-items: center;

	&:focus-within {
		.inputRadio {
			box-shadow: 0px 0px 4px rgba($yellow, 0.25);
		}
	}

	&.dark {
		color: $white;
		.inputRadio {
			border: 1px solid white;
		}
	}

	label {
		line-height: 36px;
		display: flex;
		gap: 10px;
	}

	input {
		visibility: hidden;
		position: absolute;

		&:checked {
			& + .inputRadio {
				background: $deep-green;

				.cross {
					transform: translate(3%, -50%) scale(0);
				}

				.tick {
					transform: translate(3%, -50%) scale(1);
				}

				.tick,
				.cross {
					left: 35px;
				}

				.loading {
					left: 38px;
				}

				&::before {
					left: 30px;
				}
			}
		}
	}

	&.disabled {
		opacity: 0.6;
		cursor: pointer;

		& * {
			cursor: default;
		}
	}

	.loading {
		background-image: url(/images/loading--dark.gif);
		background-size: 20px 20px;
		background-position: center center;
		background-repeat: no-repeat;
		height: 20px;
		width: 20px;
		position: absolute;
		top: 50%;
		left: 8px;
		transform: translateY(-50%);
	}
}

.inputRadio {
	position: relative;
	width: 64px;
	height: 36px;
	background: $light-grey;
	border-radius: 100px;
	display: block;
	cursor: pointer;
	flex-shrink: 0;
	transition: background-color 0.4s ease-in-out;

	&::before {
		position: absolute;
		height: 30px;
		width: 30px;
		border: 2px solid $deep-green;
		background: transparent;
		content: " ";
		top: 50%;
		left: 0px;
		transform: translate(0, -50%);
		border-radius: 100%;
		background-color: $white;
		transition: left 0.4s ease-in-out;
	}

	.tick {
		transform: translate(3%, -50%) scale(0);
	}

	.cross {
		transform: translate(3%, -50%) scale(1);
	}

	.tick,
	.cross {
		position: absolute;
		left: 5px;
		top: 50%;
		transition: transform 0.4s ease-in-out, left 0.4s ease-in-out;
	}
}
