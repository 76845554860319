@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";
@import "src/styles/utils/keyframes";

.modal-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: $z-index-above-all;
	transition: opacity 0.4s, left 0s 0.4s;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: 0.4s fade-in linear;

	& .modalWrapper {
		max-width: 90%;
		width: 300px;
		margin-top: 10px;
		position: relative;
		max-height: 90%;
		display: flex;

		@include mobileLarge() {
			max-width: 75%;
		}

		& div,
		& p,
		& h1,
		& h2,
		& h3,
		& h4 {
			color: $deep-green;
		}

		&.infoModal {
			text-align: left;
			width: 570px;
			max-width: 95%;
		}

		&.darkTheme {
			.modal {
				background: $deep-green;

				& p,
				& h1,
				& h2,
				& h3,
				& h4 {
					color: $white;
				}
			}
		}

		@include tablet() {
			margin-top: 0;
			max-height: 85%;
			max-width: 95%;
		}

		&.fullWidthMobile {
			width: calc(100% - 30px);
			max-width: calc(100% - 30px);

			&,
			&.responsiveWidth {
				@include tablet() {
					width: calc(90% - 30px);
					max-width: calc(90% - 30px);
				}
				@include tabletLarge() {
					width: 500px;
				}

				@include desktop() {
					width: 568px;
				}
			}
		}

		&.responsiveWidth {
			@include tablet() {
				width: 400px;
			}

			@include tabletLarge() {
				width: 500px;
			}

			@include desktop() {
				width: 568px;
			}
		}
	}

	.modal {
		height: auto;
		max-height: 100%;
		position: relative;
		background-color: $white;
		box-shadow: $shadow;
		transition: all 0.4s;
		overflow-x: hidden;
		border-radius: 0;
		text-align: center;
		width: 100%;
		padding: 30px 20px;
		border-radius: 0;
		text-align: center;
		overflow-y: auto;
	}
}

.modal-image {
	height: 60px;
	position: relative;
	margin-bottom: $gutter;
	width: auto;
	@include tablet() {
		height: 120px;
	}
	img {
		width: auto;
		max-height: 100%;
	}
}

.modal__close {
	width: 24px;
	height: 24px;
	position: absolute;
	overflow: hidden;
	cursor: pointer;
	z-index: 5;
	color: $white;
	right: 0;
	bottom: 102%;

	@include tablet() {
		margin-left: 480px;
		right: 5px;
		bottom: 102%;
	}
}
