@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.icon {
	margin-right: 10px;
	margin-top: auto;
	transform: translateX(0) scale(1);
	transition: transform 0.2s ease-in-out;
}

.link {
	position: relative;
	display: flex;
	justify-content: center;

	&:hover {
		& .icon {
			transform: translateX(-3px) scale(1.02);
		}
	}
}
